<template>
  <el-form size="mini" ref="form" :model="orderVo" label-width="120px" status-icon>
    <el-form-item label="订单批次" prop="orderBatch" :rules="[{required: true, message: '订单批次不能为空', trigger: 'blur'}]">
      <el-input v-model="orderVo.orderBatch" placeholder="请输入订单批次" maxlength="50" show-word-limit @change="verifyOrderBatch"></el-input>
    </el-form-item>
    <el-form-item label="供应商" prop="supplier" :rules="[{required: true, message: '供应商必选', trigger: 'blur'}]">
      <el-select v-model="orderVo.supplier" value-key="id" placeholder="请选择供应商">
        <el-option v-for="(d,i) in supplierList" :key="i" :label="d.name" :value="d"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="标签总数（套）" prop="securityNumber">
      <el-input v-model="orderVo.order.securityNumber" disabled></el-input>
    </el-form-item>
    <el-form-item label="标签">
      <el-button type="text" @click="handleSelectLabels">添加标签</el-button>
      <el-table ref="dataTable" stripe border fit size="mini" highlight-current-row :data="orderVo.labelList" style="width: 90%">
        <el-table-column label="物料代码" align="center" property="materialCode" width="100px">
            <template #default="scope">
              <span>{{scope.row.model.materialCode}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="labelApply.creatorName" label="申请人" width="80" align="center"/>
        <el-table-column  label="型号" align="center" min-width="100px" property="name">
          <template #default="scope">
            <span>{{scope.row.model.name}}</span>
          </template>
        </el-table-column>
        <el-table-column width="50px" label="单位" align="center">
          <template #default="scope">
              <span>{{scope.row.model.unit}}</span>
          </template>
        </el-table-column>
          <el-table-column width="80px" label="类型" align="center">
            <template #default="scope">
              <span>{{scope.row.model.type.title}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="manufacturer.name" label="制造商" min-width="100" align="center"/>
        <el-table-column prop="manufacturer.code" label="制造商代码" width="60px" align="center"/>
        <el-table-column prop="model.page" label="联数" width="50px" align="center"/>
        <el-table-column prop="releaseCount" label="防伪码标签（套）" min-width="80px" align="center"/>
        <!-- <el-table-column prop="startLabel" label="起始标签" min-width="150" align="center"/>
        <el-table-column prop="endLabel" label="结束标签" min-width="150" align="center"/> -->
        <el-table-column prop="orderRemark" label="下单备注" fixed="right" min-width="200px" align="center">
        <template #default="scope">
            <el-input size="small" type="textarea" rows = "2"
            v-model="scope.row.orderRemark" placeholder="请输入内容"
             @change="handleEdit(scope.$index, scope.row)"
            maxlength="50"></el-input>
        </template>
        </el-table-column>
        <el-table-column align="center" width="50px" fixed="right">
          <template #default="scope">
            <i class="el-icon-delete" style="cursor:pointer;" alt="移除标签" @click="handleRemoveLabel(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit(false)" v-waves v-loading.fullscreen.lock="fullscreenLoading">暂存</el-button>
      <el-button type="primary" @click="onSubmit(true)" v-waves v-loading.fullscreen.lock="fullscreenLoading">生成采购单</el-button>
      <el-button v-waves @click="onCancel">取消</el-button>
      <el-dialog v-model="startupFormDialogShow" :append-to-body="true" width="80%">
          <component :is="startupForm" v-model:showDialog="startupFormDialogShow"
            v-model:orderLabelList="selectLabels" v-model:orderBatch="orderBath"
            @close="startupFormDialogShow=false">
          </component>
      </el-dialog>
    </el-form-item>
  </el-form>
</template>

<style scoped>
  .el-form {
    margin: 20px;
  }

</style>

<script>
import orderTempApi from '@/apis/order/orderTemp'
import waves from '@/directive/waves' // 水波纹指令
import addLabelsForm from './addLabels'
export default {
  directives: {
    waves
  },
  props: {
    orderId: {
      type: String
    },
    showDialog:{
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      fullscreenLoading: false,
      orderBath: undefined,
      id: this.orderId,
      startupFormDialogShow: false,
      startupForm: null,
      orderVo: {
        orderBatch: undefined,
        supplier: undefined,
        order: {
          orderBatch: undefined,
          supplier: undefined,
          securityNumber: 0,
          barcodeNumber: 0
        },
        labelList: []
      },
      selectLabels:[],
      supplierList: [],
      selecteLabels: {
        show: false,
        showLoading: false,
        rows: [],
        total: 0,
        condition: {
          keyword: undefined
        }
      }
    }
  },
  watch: {
    orderId:{
      handler: function(newV, oldV){
        if(this.orderId){
          this.resetForm()
        }else{
          this.resetOrder()
        }
      }
    },
    showDialog:{
      handler: function(newV, oldV){
          if(this.showDialog){
            this.resetForm()
          }
        }
      },
    selectLabels:{
      handler: function(newV, oldV){
          if(this.selectLabels && this.selectLabels.length>0){
            this.orderVo.labelList = this.selectLabels
            var releaseCount = 0
            this.selectLabels.forEach(select=>{
              releaseCount = select.releaseCount + releaseCount
            })
            this.orderVo.order.securityNumber = releaseCount
          }else{
            this.orderVo.labelList = []
            this.orderVo.order.securityNumber = 0
          }
      }
    }
  },
  components: {
    addLabelsForm
  },
  methods: {
    /**
       * 提交数据
       * @param {*} goon 新增后是否审核
       */
    onSubmit (goon) {
      this.$refs.form.validate().then(response => {
          if (!response) {
            return
          }
          var total = 0
          var barcodeNumber =0
          var str = this.orderVo.orderBatch
          var falg = true
          var specialKey = "[`~!#$^&*()=|{}':;'\\[\\]<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
          for (var i = 0; i < str.length; i++) {
              if (specialKey.indexOf(str.substr(i, 1)) != -1) {
                  falg = false
              }
          }
          if(falg){
            this.orderVo.order.orderBatch = this.orderVo.orderBatch
            this.orderVo.order.supplier = this.orderVo.supplier
            if(this.orderVo.labelList && this.orderVo.labelList.length>0){
              this.orderVo.labelList.forEach(la=>{
                total = total + la.releaseCount
                barcodeNumber = barcodeNumber + la.releaseCount * la.model.page
              })
              this.orderVo.order.barcodeNumber = barcodeNumber
              if (!this.orderId) {
                orderTempApi.verifyOrderBatch(this.orderVo.orderBatch).then(res=>{
                  if(res.data){
                    this.$message.warning("订单批次已存在请重新输入")
                  }else{
                    orderTempApi.addOrder(this.orderVo).then(resp=>{
                      this.fullscreenLoading = true
                      if(goon){
                        var instance = {
                          businessKey: resp.data.id,
                          processDefinitionKey: 'CreateOrder'
                        }
                        orderTempApi.orderWorkflowStart(instance).then(res=>{
                          this.fullscreenLoading = false
                          this.$message.success("采购订单已生成")
                          this.resetOrder()
                          this.$emit('close')
                        })
                      }else{
                        this.fullscreenLoading = false
                        this.$message.success("保存成功")
                        this.resetOrder()
                        this.$emit('close')
                      }
                    })
                  }
                }).catch(() => this.fullscreenLoading=false)
              } else {
                orderTempApi.verifyOrderBatch(this.orderVo.orderBatch).then(res=>{
                    if(res.data && res.data.id !== this.orderId){
                      this.$message.warning("订单批次已存在请重新输入")
                    }else{
                      orderTempApi.updateOrder(this.orderVo).then(resp=>{
                        this.fullscreenLoading = true
                        if(goon){
                          var instance = {
                            businessKey: this.orderId,
                            processDefinitionKey: 'CreateOrder'
                          }
                          orderTempApi.orderWorkflowStart(instance).then(res=>{
                            this.fullscreenLoading = false
                            this.$message.success("采购订单已生成")
                            this.resetOrder()
                            this.$emit('close')
                          })
                        }else{
                          this.fullscreenLoading = false
                          this.$message.success("保存成功")
                          this.resetOrder()
                          this.$emit('close')
                        }
                      })
                    }
                }).catch(() => this.fullscreenLoading=false)
              }
            }else{
              this.$message.warning("标签不能为空")
            }
          }else{
            this.$message.warning("订单批次不可包含特殊字符")
          }
        
      })
    },
    /**
       * 取消按钮处理
       */
    onCancel () {
      this.$emit('close')
    },
    /**
       * 加载角色数据
       * @param {*} pageInfo 分页信息
       */
    getLabelList (pageInfo) {
      this.selecteLabels.showLoading = true
      orderTempApi.labelPage({ pageInfo, condition: this.selecteLabels.condition }).then(response => {
        this.selecteLabels.rows = response.data.content
        this.selecteLabels.total = response.data.totalElements
        this.selecteLabels.showLoading = false
      }).catch(response => {
        this.selecteLabels.showLoading = false
      })
    },
    /**
       * 订单信息
    */
    getOrder () {
      if (this.orderId) {
        orderTempApi.getOrderInfoById(this.orderId).then(res=>{
          this.orderVo.order = res.data
          this.orderVo.orderBatch = res.data.orderBatch
          this.orderVo.supplier = res.data.supplier
          this.orderBath = res.data.orderBatch
        })
      }else{
        this.orderBath = null
      }
    },
    getLabelInfo () {
      if (this.orderId) {
        orderTempApi.getLabelByOrderId(this.orderId).then(res=>{
          this.orderVo.labelList = res.data
          this.selectLabels = res.data
        })
      }else{
        this.selectLabels = []
      }
    },
    /**
       * 重置表单
       */
    resetForm () {
      this.getOrder()
      this.getLabelInfo()
      this.getSupplierList()
    },
    resetOrder(){
      this.orderVo = {
        orderBatch: undefined,
        supplier: undefined,
        order: {
          orderBatch: undefined,
          supplier: undefined,
          securityNumber: 0,
          barcodeNumber: 0
        },
        labelList: []
      },
      this.selectLabels = []
      this.orderBath = undefined
    },
    /**
       * 选择角色
       */
    handleSelectLabels () {
      this.startupForm = 'addLabelsForm'
      this.startupFormDialogShow = true
    },
    /**
       * 移除
       */
    handleRemoveLabel (labelInfo) {
      var total = this.orderVo.order.securityNumber
      var barcodeTotal = this.orderVo.order.barcodeNumber
      this.orderVo.labelList = this.orderVo.labelList.filter(ar => {
        if(ar.id === labelInfo.id){
          total = total - ar.releaseCount
          barcodeTotal = barcodeTotal - (ar.barcodeCount?ar.barcodeCount:0)
        }
        return !(ar.id === labelInfo.id)
      })
      this.selectLabels = this.orderVo.labelList
      this.orderVo.order.securityNumber = total
      this.orderVo.order.barcodeNumber = barcodeTotal
    },
    /**
       * 过滤角色
       */
    handleLabelsFilter (pageInfo) {
      this.$refs.labelsTable.changeCondition()
    },
    /**
       * 标签选择
       */
    handleLabelsSelected (labels) {
      var total = this.orderVo.order.securityNumber
      var barcodeTotal = this.orderVo.order.barcodeNumber
      labels.forEach(r => {
        if (!this.orderVo.labelList.find(ar => {
          return ar.id === r.id
        })) {
          total = total + r.releaseCount
          barcodeTotal = barcodeTotal + (r.barcodeCount?r.barcodeCount:0)
          this.orderVo.labelList.push(r)
        }
      })
      this.orderVo.order.securityNumber = total
      this.orderVo.order.barcodeNumber = barcodeTotal
    },
    /**
       * 选择标签窗口关闭
       */
    handleLabelsDialogClose () {
      this.selecteLabels.condition.name = undefined
      this.selecteLabels.rows = []
      this.selecteLabels.show = false
    },
    /**
       * 查询条件变更
       */
    handleLabelPageInfoChange (pageInfo) {
      this.getLabelList(pageInfo)
    },
    getSupplierList(){
      orderTempApi.getAllSupplierList().then(resp=>{
        this.supplierList = resp.data
      })
    },
    verifyOrderBatch(value){},
    onSubmitOrderWorkflow(){
      this.onSubmit(true)
    },
    handleEdit(index, row) {},
  },
  created () {
    this.getOrder()
    this.getLabelInfo()
    this.getSupplierList()
  }
}

</script>
