<template>
  <div>
    <el-divider content-position="left">标签采购</el-divider>
    <el-row>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" size="mini" placeholder="输入关键字查询" v-model.trim="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item style="margin-left: 10px;margin-right: 12px;">
        <el-button class="filter-item" size="mini" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item>
      <span style="margin-left: 10px;margin-top: 8px; color: red">标签总数（套）: {{releaseTotal}}</span>
    </el-row>
     <el-table :data="labelList" style="width: 100%" max-height="500" border ref="labelTable" @select-all="allSelect" @select="checkSelect"><!--@selection-change="selectLabel" -->
      <el-table-column type="selection" width="55" />
      <!-- <el-table-column width="80" label="表单拆分">
        <template #default="scope">
          <el-tag type="success">{{scope.row.split}}</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="申请类型" width="100" align="center" >
        <template #default="scope">
          {{scope.row.applyType&&scope.row.applyType.type}}
        </template>
      </el-table-column>
      <el-table-column label="支付类型" width="100" align="center" >
        <template #default="scope">
          {{scope.row.payType&&scope.row.payType.type}}
        </template>
      </el-table-column>      
      <el-table-column label="申请人" width="100" align="center" >
        <template #default="scope">
          {{scope.row.labelApply&&scope.row.labelApply.creatorName}}
        </template>
      </el-table-column>
      <el-table-column label="物料代码" align="center" property="name" width="120">
        <template #default="scope">
          <span>{{scope.row.model.materialCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="型号" align="center" min-width="150">
        <template #default="scope">
          <span>{{scope.row.model.name}}</span>
        </template>
      </el-table-column>
      <el-table-column width="50px" label="单位" align="center">
        <template #default="scope">
          <span>{{scope.row.model.unit}}</span>
        </template>
      </el-table-column>
      <el-table-column width="80px" label="类型" align="center">
        <template #default="scope">
          <span>{{scope.row.model.type.title}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="manufacturer.name" label="制造商" min-width="150" align="center"/>
      <el-table-column prop="manufacturer.code" label="制造商代码" width="60" align="center"/>
      <el-table-column prop="model.page" label="联数" width="50" align="center"/>
      <el-table-column prop="releaseCount" label="防伪码标签（套）" width="150" align="center"/>
      <!-- <el-table-column prop="startLabel" label="起始标签" width="220" align="center"/> -->
      <!-- <el-table-column prop="endLabel" label="结束标签" width="220" align="center"/> -->
    </el-table>
    <el-row style="margin-top: 10px">
      <el-button type="success" v-waves icon="el-icon-check" size="mini" @click="checkLabels">确认</el-button>
    </el-row>
  </div>
</template>

<script>
import orderTempApi from '@/apis/order/orderTemp'
export default {
  props: {
    orderLabelList:{
      type: Array,
      default: function () {
        return []
      }
    },
    showDialog:{
      type: Boolean,
      default: false
    },
    orderBatch:{
      type: String
    },
  },
  data() {
    return {
      releaseTotal: 0,
      orList: this.orderLabelList,
      selectLabelList: [],
      labelList: [],
      listQuery: {
        condition:{
          keyword: null,
          orderBatch: this.orderBatch ? this.orderBatch: null
        }
      },
      showLabelDialog: false
    }
  },
  watch:{
    orderBatch:{
        handler: function(newV, oldV){
          this.listQuery.condition.orderBatch = this.orderBatch
        }
      },
      showDialog:{
        handler: function(newV, oldV){
            this.showLabelDialog = this.showDialog
            if(this.showDialog){
              this.getAllLabelList()
            }
        }
      }
    },
  created() {
    this.getAllLabelList()
  },
  mounted(){
  },
  methods:{
    toggleSelection(values){
      var recount = 0
      if(values && values.length>0){
        values.forEach(m=>{
          recount = recount + m.releaseCount
          this.labelList.forEach(l=>{
            if(m.id === l.id){
              this.$refs.labelTable.toggleRowSelection(l, true)
            }
          })
        })
      }
      this.releaseTotal = recount
    },
    checkLabels(){
      this.reduce(this.orList,this.selectLabelList)
    },
    reduce(valuesOne,valuesTwo){
      var selectInfo = []
      var selectValues = []
      if(valuesOne){
        valuesOne.forEach(a=>{
          selectInfo.push(a)
        })
      }
      if(valuesTwo){
        valuesTwo.forEach(b=>{
          selectInfo.push(b)
        })
      }
      const res = new Map()
      selectValues = selectInfo.filter(arr => !res.has(arr.id) && res.set(arr.id, 1))
      this.$emit('update:orderLabelList', selectValues)
      this.$emit('close',false)
    },
    getAllLabelList(){
      orderTempApi.allLabelList(this.listQuery.condition).then(resp =>{
        if(resp.data){
          this.labelList = resp.data
          this.toggleSelection(this.orderLabelList)
        }
      })
    },
    selectLabel(selection){
      this.selectLabelList = selection
      var releaseCount = 0
      var selectInfo = []
      var selectValues = []
      if(this.selectLabelList){
        this.selectLabelList.forEach(a=>{
          selectInfo.push(a)
        })
      }
      if(this.orList){
        this.orList.forEach(b=>{
          selectInfo.push(b)
        })
      }
      const res = new Map()
      selectValues = selectInfo.filter(arr => !res.has(arr.id) && res.set(arr.id, 1))
      if(selectValues && selectValues.length>0){
        selectValues.forEach(select => {
          releaseCount = select.releaseCount + releaseCount
        })
      }
      this.releaseTotal = releaseCount
    },
    /**
     * 搜索
     */
    handleFilter () {
      this.getAllLabelList()
    },
    checkSelect(selection, row){
      var labelInfo = null
      //是否为之前选中的数据
      if(this.orList){
        labelInfo = this.orList.filter(or=>or.id === row.id)
      }
      //console.log('labelInfo:',labelInfo)
      var label = null
      //选中还是取消勾选
      if(selection){
        label = selection.filter(se=>se.id === row.id)
      }
      //console.log('label:',label)
      if((!label || label.length<1) && labelInfo && labelInfo.length>0){
        var orList = this.orList.filter(or=>or.id != row.id)
        this.orList = orList
        var reotal = this.releaseTotal - row.releaseCount
        this.releaseTotal = reotal
      }else {
        this.selectLabel(selection)
      }
    },
    allSelect(selection){
      //console.log('selection:',selection)
        //全部取消勾选
        if(!selection || selection.length<1){
          if(this.orList && this.orList.length>0){
            //哪些是之前勾选的被取消了
            var llList = []
            this.orList.forEach(m=>{
              this.labelList.forEach(l=>{
                if(m.id === l.id){
                  llList.push(l)
                }
              })
            })
            if(llList && llList.length>0){
              var reotal = 0
              var lists =[]
              llList.forEach(lls=>{
                lists = this.orList.filter(or=>lls.id != or.id)
                reotal = this.releaseTotal - lls.releaseCount
              })
              this.orList = lists
              this.releaseTotal = reotal
            }else{
              this.selectLabel(selection)
            }
          }else{
            this.selectLabel(selection)
          }
        }else{
          this.selectLabel(selection)
        }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>