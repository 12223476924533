import api from '../api'
/**
 * 标签采购-暂存单据
 */
export class OrderTempApi extends api {
    addOrder(data){
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/addOrder`,
          method: "post",
          data
        })
    }
    updateOrder(data){
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/updateOrder`,
          method: "put",
          data
        })
    }
    labelPage ({
        pageInfo,
        condition
      }) {
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/labelPage`,
          method: 'post',
          pageInfo,
          data: condition
        })
      }
    getAllSupplierList(){
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getAllSupplierList`,
            method: "get"
        })
    }
    getOrderInfoById (id) {
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/getOrderInfoById/${id}`,
          method: 'get'
        })
    }
    getLabelInfoByOrderId (orderId) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getLabelInfoByOrderId/${orderId}`,
            method: 'get'
        })
    }
    verifyOrderBatch (orderBatch) {
      return this.request({
          url: `${this.servicePath}/${this.modulePath}/verifyOrderBatch/${orderBatch}`,
          method: 'get'
      })
  }
  orderWorkflowStart(data){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/orderWorkflow/start`,
      method: "post",
      data
    })
  }
  allLabelList(condition){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/allLabelList`,
      method: 'post',
      data: condition
    })
  }
  getLabelByOrderId (orderId) {
    return this.request({
        url: `${this.servicePath}/${this.modulePath}/getLabelByOrderId/${orderId}`,
        method: 'get'
    })
  }
}

export default new OrderTempApi('holdBillsOfOrder')